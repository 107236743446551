import gql from "graphql-tag";

export const createPlayer = gql`
  mutation registerUser($registerUser: registerUserInput!) {
    registerUser(registerUser: $registerUser) {
      id
      username
      email
      lastName
    }
  }
`;
     
export const boostMutation = gql` 
  mutation boostActivation($boostActivation: BoostInput!){ 
    boostActivation(boostActivation: $boostActivation) {
      activation
      timeBeforeActivation
      boostTime
    
    }
  }
`;


export default {
  createPlayer,
  boostMutation,
}