import gql from "graphql-tag";

export const STREAM_SUBSCRIPTION = gql`
  subscription getStartStream {
    stream {
      token
      url
      type
    }
  }
`;
export const WAIT_LIST_SUBSCRIPTION = gql`
  subscription getWaitlistPosition {
    waitList {
      queue_position
    }
  }
`;

export const CAR_COLLISION = gql`
  subscription getCarCollision {
    carCollision {
      localisation
      intensity  
    }
}
`;


export default {
  STREAM_SUBSCRIPTION,
  WAIT_LIST_SUBSCRIPTION,
  CAR_COLLISION,
}
