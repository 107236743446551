<template>
  <div id="controller-id" class="controller">
    <!-- Controller value should be between -100 <-> 100 -->
    <Slider v-if="controlMode == '0'"
      :primaryColor="colorValue(color.color1)"
      :secondaryColor="colorValue(color.color2)"
      :accentColor="colorValue(color.color3)"
      :isVertical="isAcceleration"
      @sliderInput="sendInput"
    >
    </Slider>

    <Joystick v-if="controlMode == '1'"
      :isAcceleration ="isAcceleration"
      @joystickInput ="sendInput">
    </Joystick>

    <div class="speedButtons" v-if="controlMode == '2'">
      <AccelerationButton
      class="acceleration"
     :isAcceleration ="isAcceleration"
      @acelerationInput ="sendInput">
    </AccelerationButton>
    <ReverseButton
    class="reverse"
     :isAcceleration ="isAcceleration"
      @reverseInput ="sendInput">
    </ReverseButton>
  </div>

    <div class="turnButtons" v-if="controlMode == '3'">
    <LeftButton
    class="turnLeft"
      :isAcceleration ="isAcceleration"
      @leftInput ="sendInput">
    </LeftButton>
    <RightButton
    class="turnRight"
      :isAcceleration ="isAcceleration"
      @rightInput ="sendInput">
    </RightButton>
  </div>

  <div class="accelerationSlider" v-if="controlMode == '4'">
    <NewSliderAcceleration
    :isAcceleration ="isAcceleration"
      @newSliderAccelerationInput ="sendInput">
    </NewSliderAcceleration>
  </div>

  <div class="TurnSlider" v-if="controlMode == '5'">
    <NewSliderTurn
    :isAcceleration ="isAcceleration"
      @newSliderTurnInput ="sendInput">
    </NewSliderTurn>
  </div>

   
  </div>
</template>

<script>
//controller components
import Slider from "./controllers/Slider.vue";
import Joystick from "./controllers/JoystickC.vue";
import AccelerationButton from "./controllers/AccelerationButton.vue";
import ReverseButton from "./controllers/ReverseButton.vue";
import LeftButton from "./controllers/LeftButton.vue";
import RightButton from "./controllers/RightButton.vue"
import NewSliderAcceleration from "./controllers/NewSliderAccelerartion.vue";
import NewSliderTurn from "./controllers/NewSliderTurn.vue";

import carControl from "@/services/carControl";
import { mapState } from "vuex";

export default {
  name: "Controller",
  data() {
    return {
      controlerValue: {
        acceleration: {
          value: 0,
          modifier: 1,
        },
      },
      controlColor: null,
      controllerId: "",
    };
  },
  props: {
    color: {
      type: Object,
      default: () => ({
        color1: "--primary-color",
        color2: "--secondary-color",
        color3: "--primary-accent",
      }),
    },
    isAcceleration: Boolean,
    controlMode: String,
  },
  components: { Slider, Joystick,AccelerationButton,ReverseButton,LeftButton,RightButton,NewSliderAcceleration,NewSliderTurn},
  mounted() {
    const controller = document.getElementById("controller-id");
    this.controllerIdid = "controller-" + this.guidGenerator();
    controller.setAttribute("id", this.controllerIdid);
    if (this.isAcceleration) {
      document.getElementById(this.controllerIdid).classList.add("right");
    } else {
      document.getElementById(this.controllerIdid).classList.add("left");
    }
  },
  computed: {
    ...mapState({
      accelerationModifier: (state) => state.Control.controls.acceleration.modifier,
      deadZone: (state) => state.Control.controls.deadZone,
    }),
 
  },
  watch: {
    accelerationModifier(modifier) {
      if (this.isAcceleration) {
        console.log("modifier state changed Nitro!!!!!!!!!!!!! ", modifier);
        this.controlerValue.acceleration.modifier = modifier;
        carControl.acceleration(
          "move",
          this.controlerValue.acceleration.value *
            this.controlerValue.acceleration.modifier
        );
      }
    },
  },
  methods: {
    sendInput(data) {
  if (this.isAcceleration) {
    if (this.$store.state.isWatingInVersus) {
      console.log("is wating in versus");
      carControl.acceleration(data.mode, 0);
    } else {
      this.controlerValue.acceleration.value = data.value;
      if (Math.abs(this.controlerValue.acceleration.value) >= this.deadZone) {
        carControl.acceleration(
          data.mode,
          data.value * this.controlerValue.acceleration.modifier
        );
        console.log("Is OUTSIDE OF Dead Zone Acceleration -> " + data.value);
      } else if (Math.abs(this.controlerValue.acceleration.value) <= this.deadZone) {
        carControl.acceleration(data.mode, 0);
        console.log("Is INSIDE OF Dead Zone Acceleration -> 0");
      }
    }
  } else {
    carControl.steering(data.mode, data.value);
    console.log("Is Steering -> " + data.value);
  }
},
    colorValue(name) {
      return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(name);
    },
    controllerModeUpdated() {
      this.leftControl = this.$store.state.controlls.left.mode;
      this.rightControl = this.$store.state.controlls.left.mode;
    },
    guidGenerator() {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
      );
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/components/Controller.css";
</style>
