import Vue from "vue";
import Vuex from "vuex";
import Auth from "./Auth";
import Settings from "./Settings";
import Control from "./Control";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isWatingInVersus:false,
    singleAccount: true,
    isFold3: null,
    isInside: false,
    screenHeight : 0,
    isTelevision: null,
    userRole: null,
    isVersusUser: false,
    isChronoUser: false,
    isAdmin: false,
    isSpecial: false,
    raceDay: 1,
    currentUserId: null,
    header: true,
    carName: "Car",
    isPhoneRegistered: true,
    isRegistered: false,
    currentPseudo: "User",
    currentName: "",
    currentEmail: "",
    lapCount: 0,
    maxLaps: 3,
    streamType: "SPECTATOR",
    chrono: {
      "time-m": "00",
      "time-s": "00",
      "time-ms": "000",
      "time-last-lap": 0,
    },
    lapsTime: {
      lapTime: [],
      lastLapTime: null,
      bestLapTime: null,
    },
    gameAttempt: {
      currentAttempt: 1,
      maxAttempt: 3,
    },
    raceStart: {
      isRaceStarted: false,
      isChronoStarted: false,
      isVersusStarted: false,
      isVersusLapOne: false,
      lapDetection: true,
    },
    carData: {
      carID: null,
      carTagID: null,
      dirCalibration: 0,
      speedCalibration: 1,
    },
  },
  mutations: {
    incrementLaps(state) {
      state.lapCount++;
    },
    resetLaps(state) {
      state.lapCount = 0;
    },
    raceStartedState(state) {
      state.raceStart.isRaceStarted = !state.raceStart.isRaceStarted;
    },
    raceChronoState(state) {
      state.raceStart.isChronoStarted = !state.raceStart.isChronoStarted;
    },
    raceVersusState(state) {
      state.raceStart.isVersusStarted = !state.raceStart.isVersusStarted;
    },
    raceVersusLap1State(state) {
      state.raceStart.isVersusLapOne = !state.raceStart.isVersusLapOne;
    },
    incrementRaceAttempt(state) {
      state.gameAttempt.currentAttempt++;
    },
    resetRaceAttempt(state) {
      state.gameAttempt.currentAttempt = 1;
    },
    updateRaceDay(state, value) {
      console.log("RaceDay VAL = ", value);
      state.raceDay = value;
    },
    updateRaceLaps(state, value) {
      console.log("RaceLaps VAL = ", value);
      state.maxLaps = value;
    },
    updatedirCali(state, value) {
      console.log("DirCali VAL = ", value);
      state.carData.dirCalibration = value;
    },
  },
  actions: {
    startRace(context) {
      context.commit("raceChronoState");
      context.commit("incrementLaps");
    },
  },
  modules: {
    Auth,
    Settings,
    Control,
  },
  getters: {
    raceStart: (state) => {
      return state.raceStart;
    },
  },
});
