<template>

  <div class="reverseContainer">
    <!-- Button Image -->
    <input
      type="button"
      alt="reverse control"
      :style="{ width: buttonSize}"
      @mousedown="startReversing"
      @mouseup="stopReversing"
      @mouseleave="stopReversing"
      @touchstart="startReversing"
      @touchend="stopReversing"
      @touchcancel="stopReversing"
      :value="value"
      @input="updateValue($event.target.value)"
    />
    <!-- <img src="../../../assets/images/control/arrow-button.png" alt="reverse control"
      :width="buttonSize" 
      @mousedown="startReversing" 
      @mouseup="stopReversing" 
      @mouseleave="stopReversing"
      @touchstart="startReversing"
      @touchend="stopReversing"
      @touchcancel="stopReversing"
      :value="value"
      v-on:input="updateValue($event.target.value)"
    > -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      buttonSize: 65, // Button size
      speed: 0, // initial speed
      minSpeed: -100, // speed minimum
      timer: null, // to stop aceletation
      mode: "end",
      resetTimout: null,
      value: 0,
      imageSrc:"../../../assets/images/control/arrow-button.png"
    };
  },
  props: {
       isAcceleration: Boolean,
  },

  methods: {
    
    startReversing() {
      this.mode = "move";
      this.speed = this.minSpeed;
      this.updateValue( Math.round(this.speed));
      clearTimeout(this.resetTimout);
      console.log("run-Reversing");
        
  
      
    },
    stopReversing() {
        this.mode = "end";
        this.speed = 0;
        this.updateValue(Math.round(this.speed));
        console.log("stop-Reversing");
        
    },
    updateValue: function (speed) {
      this.$emit("reverseInput", {// child -> parent
        mode: this.mode,
        value: speed,
      });
      console.log("send-reverse");
    },
  },
};
</script>
  
  <style scoped>
  @import "../../../assets/css/controls/ReverseButton.css";
  </style>