<template>
  <!-- Componente del botón de reversa -->
  <div class="leftContainer">
    <Boost class="boost" v-if="!isAcceleration"></Boost>
    <!-- Imagen del botón de reversa -->
    <input
      type="button"
      :style="{ width: buttonSize}"
      @mousedown="startTurning" 
      @mouseup="stopTurning" 
      @mouseleave="stopTurning"
      @touchstart="startTurning"
      @touchcancel="stopTurning"
      @touchend="stopTurning"
      :value="value"
      v-on:input="updateValue($event.target.value)"
    >
  </div>
</template>
<script>
import Boost from "../actions/boost.vue";
export default {
  data() {
    return {
      image:"../../../assets/images/actionButton.svg",
      buttonSize: 70, // Button size
      turning: 0, //initial value
      minTurning: -100, // max value
      turningRate: 20, // incrimentation of value
      returningRate: 40,  // desincrementation of value
      timer: null, // stop turning
      mode: "end",
      resetTimout: null,
      value: 0,
    };
  },
  components: {
    Boost,
  },
  props: {
       isAcceleration: Boolean,
  },
  methods: {
    
    startTurning() {// Función para iniciar la reversa al presionar el botón
      this.mode = "move";
        this.timer = setInterval(() => {
          this.turning = Math.max(this.turning - this.turningRate, this.minTurning);
          clearTimeout(this.resetTimout);
          this.updateValue( Math.round(this.turning));
       // console.log("reversing by : " + Math.round(this.speed));
        }, 50);
      
    },
    stopTurning() {// Función para detener la reversa al soltar el botón
      clearInterval(this.timer);
      this.mode = "end"; 
      this.turning = 0;
      this.updateValue(Math.round(this.turning));
        // const timer = setInterval(() => {
        //   this.turning = Math.min(this.turning + this.returningRate, 0);
        //   //console.log("returning to : " + Math.round(this.turning));
        //   this.updateValue(Math.round(this.turning));
        //   if (this.turning === 0) clearInterval(timer);
        // }, 50);
    },
    updateValue: function (turning) {
      this.turning = turning;
      this.$emit("leftInput", {// child -> parent
        mode: this.mode,
        value: turning,
      });
    },
  },
};
</script>
  
  <style scoped>
  @import "../../../assets/css/controls/LeftButton.css";
  </style>