<template>
  
  <div class="rightContainer">
    <!-- Button Image -->
    <input
      type="button"
      alt="right control"
      :style="{ width: buttonSize}"
      @mousedown="startTurning"
      @mouseup="stopTurning"
      @mouseleave="stopTurning"
      @touchstart="startTurning"
      @touchcancel="stopTurning"
      @touchend="stopTurning"
      :value="value"
      v-on:input="updateValue($event.target.value)"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      buttonSize: 70, // Button size
      turning: 0, //initial value
      maxTurning: 100, // max value
      turningRate: 20, // incrimentation of value
      returningRate: 40,  // desincrementation of value
      timer: null, // stop turning
      mode: "end",
      resetTimout: null,
      value: 0,
    };
  },
  props: {
       isAcceleration: Boolean,
  },
  methods: {
    startTurning() {
      this.mode = "move";
      this.timer = setInterval(() => {//Starts the timer to call the methode reverse every 50ms
      // increases the turning value as long as it is not the maximum speed  
      this.turning = Math.min(this.turning + this.turningRate, this.maxTurning);
      this.updateValue( Math.round(this.turning));

      }, 50);
    },
    stopTurning() {
      clearInterval(this.timer);
      this.mode = "end"; 
      this.turning = 0;
      this.updateValue(Math.round(this.turning));
        // const timer = setInterval(() => {
        //   this.turning = Math.min(this.turning + this.returningRate, 0);
        //   //console.log("returning to : " + Math.round(this.turning));
        //   this.updateValue(Math.round(this.turning));
        //   if (this.turning === 0) clearInterval(timer);
        // }, 50);
    },
    updateValue: function (turning) {
      this.turning = turning;
      this.$emit("rightInput", {// child -> parent
        mode: this.mode,
        value: turning,
      });
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/controls/RightButton.css"; 
</style>


