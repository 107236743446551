<template>
  <v-dialog v-model="show" width="300" persistent>
    <v-card>
      <v-card-text class="pt-6">
        <slot> </slot>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="showBack"
          color="black"
          outlined
          @click.stop="actionPressed(false)"
        >
          {{ returnText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="showRetry"
          @click.stop="actionPressed(true)"
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    returnText: String,
    actionText: String,
    showRetry: Boolean,
    showBack: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    actionPressed(Bool) {
      // this.show = false
      this.$emit("actionBtnPressed", Bool);
    },
  },
};
</script>

<style scoped>
@import "../assets/css/components/ValidationForm.css";
</style>
