<template>
  <div class="register">
    <div class="notificationContainer" v-if="isShowingNotification">{{ errorMessage }}</div>
    <v-container
      class="register"
      fluid
      style="padding-right: 50px; padding-left: 50px"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <div class="img-center">
            <img class="logo" src="../assets/images/Logo-Vrombr-B&W.png" />
          </div>
          <v-card class="mx-auto" max-width="344" elevation="0">
            <v-text-field
              v-if="!isContinue"
              class="ma-0 pa-0"
              @keydown.enter.prevent="validate"
              v-model="user.pseudo"
              :label="$t('home.pseudo')"
              autocomplete="off"
              required
              :rules="nicknameRules"
              counter="10"
            ></v-text-field>
            <v-text-field
              v-if="isContinue"
              v-model="user.name"
              :label="$t('home.name')"
              autocomplete="off"
              required
              :rules="nameRules"
            ></v-text-field>
            <v-text-field
              v-if="isContinue"
              v-model="user.email"
              :label="$t('home.email')"
              autocomplete="off"
              required
              :rules="emailRules"
            ></v-text-field>
          </v-card>
        </v-container>
      </v-form>
      <v-container style="padding-top: 10px; text-align: center">
        <v-btn
          v-if="!isContinue"
          :disabled="user.pseudo === '' || user.pseudo.length > 10"
          @click="continueValidation"
          @touchstart="continueValidation"
          @touchenter="continueValidation"
        >
          {{ $t("home.continue") }}
        </v-btn>
        <v-btn
          v-if="isContinue"
          @click="validate"
          @touchstart="validate"
          @touchenter="validate"
          :disabled="!validateForm() "

        >
          {{ $t("home.save") }}
        </v-btn>
      </v-container>
      <div class="errorForm" v-html="error" />
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      errorMessage: "",
      isShowingNotification: false,
      isContinue: false,
      valid: false,
      nicknameRules: [
        (v) => !!v || "Nickname is required" || false,
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters" || false,
      ],
      emailRules: [
      (v) => false || !!v  ,
      (v) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return false || emailRegex.test(v) ;
      },
      ],
      nameRules: [
      (v) => !!v || "Name is required" || false,
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      user: {
        pseudo: "",
        name: "",
        email: "",
      },
      error: null,
      isValidationInProgress: false,
    };
  },
  methods: {
    ...mapActions({
      registerUser: "Auth/registerUser",
    }),

    validateForm () {
    //verrification 
      const isNameValid = this.user.name !== '' && this.nameRules.every(rule => rule(this.user.name));
      const isEmailValid = this.user.email !== '' && this.emailRules.every(rule => rule(this.user.email));
      const isNicknameValid = this.user.pseudo !== '' && this.nicknameRules.every(rule => rule(this.user.pseudo));
      console.log(isNameValid + " "+ isEmailValid + " " + isNicknameValid);
    return isNameValid && isEmailValid && isNicknameValid;

    },

    continueValidation() {
      this.isContinue = true;
    },

    async validate() {
      if (this.isValidationInProgress) {
        return; //avoid double validation
      }
      this.isValidationInProgress = true;

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          console.log("resolving ....");
        }, 500);
      });
      await this.$nextTick(); //awaits DOM

      if (this.$refs.form) {
        // awaits form render
        this.$refs.form.validate();
      } else {
        console.error("Form reference not found");
      }

      if(this.validateForm){// if pseudo, name and email are not empty 
        await this.createUserInKeycloak(this.user.name, this.user.pseudo, this.user.email);
      } else {
        console.log("No registration");
        return;
      }
      this.isValidationInProgress = false;
      // this.$router.replace({ path: `/user/${userID}` });
    },
    
    async register(userName) {
      try {
        console.log("user in form ", userName);
        const user = await this.registerUser(userName);
        const userID = user.data.registerUser.id;
        return userID;
      } catch (error) {
        console.log(error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const firstError = error.graphQLErrors[0]
      const { code, message } = firstError.extensions
      console.error(`GraphQL Error: ${code} - ${message}`);
      this.handleRegistrationErrors(code);
    }
        
      }
    },

    async registerInConsole() {
      try {
        this.$emit(
          "registered",
          this.user.pseudo,
          this.user.name,
          this.user.email
        );

      } catch (error) {
        console.log(error);
        this.error = error.response.data.error;
      }
    }, 

    //Create Mutation
    async createUserInKeycloak(name, pseudo, email) {
      
  try {
      if (this.$keycloak.authenticated) {
          
          console.log("is Authenticated");
          const userData = {
            username: pseudo,
            name: name,
            email: email,
            enabled: true,
          };

    // Create Mutation
    let response = await this.register({
      pseudo: userData.username,
      lastName: userData.name,
      email: userData.email,
    });

    if (response.errors) {
      this.handleRegistrationErrors(response.errors);
    } else {
    const userID = response;
      this.$store.state.currentUserId = userID;
      this.$store.state.currentPseudo = this.user.username;
      this.$store.state.currentName = this.user.name;
      this.$store.state.currentEmail = this.user.email;
      console.log("User created:", userID);
      await this.registerInConsole();
    }
      }else {
          console.error("this user is not authenticated in Backend");
        } 
      }catch (error) {
    console.error("Error CreatinhUser in Backend:", error);
  }
},

handleRegistrationErrors(errors) {
  console.log("errorContainer");
  //console.log(errors);

  if (JSON.stringify(errors).includes("USER_EXISTS_ERROR")) {
  this.handleRegistrationError("Nickname already exists. Please choose another one.");
  this.isContinue = false;

} else if (JSON.stringify(errors).includes("EMAIL_EXISTS_ERROR")) {
  this.handleRegistrationError("Email address is already in use. Please use a different one.");

} else if (JSON.stringify(errors).includes("USER_REGISTRATION_FAILED")) {
  this.handleRegistrationError("Registration failed. Please try again later.");
  this.isContinue = false;

} else if (JSON.stringify(errors).includes("EMAIL_VALIDATION_ERROR")) {
  this.handleRegistrationError("Error validating email address. Please ensure it's valid.");

} else {
  console.log("Special Error: " + JSON.stringify(errors));
  this.handleRegistrationError("An unexpected error occurred: " + JSON.stringify(errors));
  this.isContinue = false;
}

},

handleRegistrationError(errorMessage) {
  console.log("Error: " + errorMessage);
  this.errorMessage = errorMessage;
  this.isShowingNotification = true;
  // this.$store.state.currentPseudo = "";
  // this.$store.state.currentName = "";
  // this.$store.state.currentEmail = "";

  setTimeout(() => {
    this.isShowingNotification = false;
  }, 5000); 
},

  },
};
</script>

<style scoped>
@import "../assets/css/components/RegisterForm.css";
</style>
