<template>
    <!-- <div class="logOutContainer"> -->
        <!-- <div class="userName">
            <p>hello, </p>
            <p>{{ this.$store.state.Auth.user.name }}</p>
        </div> -->
    <div class="logOutContainer-button">
        <div @click.stop="LogoutForm = true"
             @touchstart.stop="LogoutForm = true"
             @touchenter.stop="LogoutForm = true">
            {{ $t("app.logout.label") }}
          </div>
    
          <LogoutForm
      v-on:actionBtnPressed="validationFormAction"
      v-model="LogoutForm"
      :returnText="this.$t('actions.no')"
      :actionText="this.$t('actions.yes')"
      :showBack="true"
      :showRetry="true"
    >
      {{ $t("app.logout.validationMessage") }}
    </LogoutForm>
</div>
<!-- </div> -->
</template>
<script>
import Vue from "vue";
import LogoutForm from "@/components/ValidationForm";
export default {
  data: () => ({
    LogoutForm: false,
  }),
  components: {
    LogoutForm,
  },
  methods: {
    logout() {
      Vue.$keycloak.logout({ redirectUri: window.location.origin });
    },
    validationFormAction(Bool) {
      if (Bool) {
        this.LogoutForm = false;
        this.logout();
      } else {
        this.LogoutForm = false;
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/css/components/LogOut.css";
</style>