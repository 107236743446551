<template>
  <div class="text-center">
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              class="avatar"
              alt="Avatar"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{
              this.$store.state.Auth.user.name
            }}</v-list-item-title>
            <v-list-item-subtitle>Ranked 1e</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <LocaleSwitcher />
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <div @click.stop="LogoutForm = true">
            {{ $t("app.logout.label") }}
          </div>
        </v-list-item>
      </v-list>
    </v-card>

    <LogoutForm
      v-on:actionBtnPressed="validationFormAction"
      v-model="LogoutForm"
      :returnText="this.$t('actions.no')"
      :actionText="this.$t('actions.yes')"
      :showBack="true"
      :showRetry="true"
    >
      {{ $t("app.logout.validationMessage") }}
    </LogoutForm>
  </div>
</template>

<script>
import Vue from "vue";
import LogoutForm from "@/components/ValidationForm";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  data: () => ({
    LogoutForm: false,
  }),
  components: {
    LogoutForm,
    LocaleSwitcher,
  },
  methods: {
    logout() {
      Vue.$keycloak.logout({ redirectUri: window.location.origin });
    },
    validationFormAction(Bool) {
      if (Bool) {
        this.LogoutForm = false;
        this.logout();
      } else {
        this.LogoutForm = false;
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/css/components/OptionMenu.css";
</style>