<template>
  <div class="home">
    <PageHeader v-if="this.$store.state.Auth.autStatus" />
    <!-- <RegistUser></RegistUser> -->

    <v-container class="registerForm" v-if="this.$store.state.isRegistered == false && isB2b && (this.$store.state.currentName == ''||
    this.$store.state.currentEmail == '' || this.$store.state.currentPseudo == 'User' || this.$store.state.currentPseudo == '') ">
      <v-row class="registerContainer">
        <RegisterForm 
        v-on:registered="afterRegisteredValidation" />
      </v-row>
    </v-container>

    <div class="fillHeight">
      <TransitionPage
        :isButton="true"
        :hasHeader="true"
        :message="this.$t('home.play')"
        :circleColor = "colorValue(color.colorCircle)"

      />
    </div>

  </div>
</template>

<script>
 import RegisterForm from "@/components/RegisterForm";
//import RegistUser from "../views/Registration.vue";
import PageHeader from "@/components/Header.vue";
import TransitionPage from "@/components/TransitionScreen.vue";
import getEnv from "../config/env.js";



export default {
  name: "Home",
  data() {
    return {
      isB2b : false,
      fullHeight : this.$store.state.screenHeight, 
    };
  },
  props :{
    color: {
      type: Object,
      default: () => ({
        colorCircle: "--loading-circle-color",
      }),
    },
  },
  components: {
     RegisterForm,
    //RegistUser,
    PageHeader,
    TransitionPage,
  },
  mounted: function (){
    this.showForm();
    console.log('no    ' + this.$store.state.isInside)
    this.$store.state.isInside = true;
    console.log('yes    ' + this.$store.state.isInside)
    
    // if(this.$store.state.isFold3 == true){
    //   console.log(this.$store.state.screenHeight+'                px')
    //   document.documentElement.style.setProperty("--height-container",this.$store.state.screenHeight+'px');
    // }else{
    //   document.documentElement.style.setProperty("--height-container", '100vh');
    // }

  },

  watch : {
    fullHeight(newVal){
      document.documentElement.style.setProperty("--height-container", newVal+'px');
      if(this.$store.state.isFold3 == true){
      document.documentElement.style.setProperty("--height-container", newVal+'px');
    }else{
      document.documentElement.style.setProperty("--height-container", '100vh');
    }
    }
  },
  methods: {


    showForm (){
      if(getEnv("VUE_APP_VROMBR") === "B2B"){
        this.isB2b = true
        if(this.$store.state.isFold3 == true){
      console.log(this.$store.state.screenHeight+'                px')
      document.documentElement.style.setProperty("--height-container",this.$store.state.screenHeight+'px');
    }else{
      document.documentElement.style.setProperty("--height-container", '100vh');
    }
    } else {
        this.isB2b = false
  } 
    },
    afterRegisteredValidation: function (pseudo) {
      this.$store.state.isRegistered = true;
      this.$store.state.currentPseudo = pseudo;
      console.log (" home "+ this.$store.state.currentPseudo);
    },
    colorValue(name) {
      return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(name);
    },
  },
  beforeMount() {
    if (this.$store.state.isTelevision) {
      this.$router.replace({ path: "/user/thisuserisatelevision" });
    }
  },
};
</script>

<style scoped>
@import "../assets/css/views/Home.css";
</style>
