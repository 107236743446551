<template>
  <div class="gameUI">
    <div class="layer-interface">
      <div class="border-l"></div>
      <div class="border-r"></div>
      <div class="border-t"></div>
      <div class="border-b"></div>
      <!-- <div v-if="this.isDriver" class="laps-info-nb fontrace">
        <span class="unselectable laps-info-nb-var info-var">
          {{ currentLap }}/{{ numLaps }}
        </span>
        <span class="unselectable laps-info-nb-str info-exp info-exp-l">
          <p>Laps</p>
        </span>
      </div>
      <div
        v-if="this.isDriver"
        class="unselectable info-time-global-str info-time-str box-bg fontrace"
      >
        <p>Current</p>
      </div>
      <div
        v-if="this.isDriver"
        id="race-chrono"
        class="unselectable info-time-global-var info-time-var box-bg fontrace"
      >
        <p>
          {{ currentTimeStr }}
        </p>
      </div>
      <div
        v-if="this.isDriver"
        class="unselectable info-time-lap-str info-time-str box-bg fontrace"
      >
        <p>Best</p>
      </div>
      <div
        v-if="this.isDriver"
        class="unselectable info-time-lap-var info-time-var box-bg fontrace"
      >
        <p>{{ bestLapStr }}</p>
      </div>-->
      <div class="pseudo-logo-var">
        <img
          v-if="isFold3"
          class="logo-img"
          alt="VROMBR"
        />
        <div v-if="!isFold3 && !isTelevision" class="unselectable back-form">
          <span v-if="isDriver">{{ player }}</span>
          <span v-if="!isDriver && waitListPosition == 0">
            {{ waitListPosition + 1 }} player before you</span
          >
          <span v-if="!isDriver && waitListPosition > 0">
            {{ waitListPosition + 1 }} players before you</span
          >
        </div>
      </div>
      <div v-if="!isTelevision">
        <!-- <div v-if="isDriver">
          <img
            id="boost"
            v-if="boostAvailable"
            class="boost-img"
            src="../assets/Bouton-nitro-disponible.png"
          />
          <img
            v-else
            class="boost-img"
            src="../assets/Bouton-nitro-non-disponible.png"
          />
        </div> -->
      </div>
      <div class="speed-var fontrace"></div>
      <div id="acc-bar" class="acceleration-bar"></div>
      <div v-if="isFold3 && !isTelevision" class="pseudo-var fontrace">
        <div class="unselectable back-form">
          <span v-if="isDriver">{{ player }}</span>
          <span v-if="!isDriver && waitListPosition == 0">
            {{ waitListPosition + 1 }} player before you</span
          >
          <span v-if="!isDriver && waitListPosition > 0">
            {{ waitListPosition + 1 }} players before you</span
          >
        </div>
      </div>
      <div class="main">
        <p class="countdown fontrace">{{ countdown }}</p>
      </div>
      <div class="footer1"></div>
      <div class="footer2"></div>
      <img
        v-if="isFold3"
        class="f3-sponsor"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { msToString, bestLap } from "../tools";

export default {
  name: "GameUi",
  props: [
    "raceMode",
    "player",
    "isDriver",
    "isSpectator",
    "waitListPosition",
    "laps",
    "currentLap",
    "numLaps",
    "startTimeMs",
    "isTelevision",
    "localTimeMsAhead",
  ],
  data() {
    return {
      currentTime: 0,
      countdown: "",
      chronoTimer: null,
      // boostAvailable: true,
    };
  },
  created() {
    this.startChronoRefresh();
    console.log("race mode in  GUI "+this.raceMode)
  },
   mounted() {
    //   this.initTouchEvent();
    this.heightFullScreen();
   },

   methods: {
    
  heightFullScreen() {
  let containerHeight;
  const heightInterface = document.querySelector('div.layer-interface');
  if (this.$store.state.isFold3) {
    console.log("fold controls   "  + "container Height - " + containerHeight )
    containerHeight = this.$store.state.screenHeight+"px";
  } else {
    console.log("normal controls")
    containerHeight = "100vh";
  }
  heightInterface.style.height = containerHeight;
  console.log("screen height  -> "+heightInterface.style.height);
  return containerHeight
},
    // boostUp() {
    //   console.log("boost recharged");
    //   this.boostAvailable = true;
    // },
    // boostDeactivation() {
    //   console.log("boost deactivated");
    //   this.$emit("boost", false);
    // },
    // useBoost() {
    //   if (this.boostAvailable == true) {
    //     console.log("boost activated");
    //     this.$emit("boost", true);
    //     setTimeout(this.boostUp, 15000);
    //     setTimeout(this.boostDeactivation, 3000);
    //     this.boostAvailable = false;
    //   } else {
    //     console.log("boost not enabled");
    //   }
    // },
    displayCountdown(delayBeforeStart) {
      if (delayBeforeStart > 6000 || delayBeforeStart < -1000) {
        this.countdown = "";
      } else {
        for (let i = 0; i <= 5; i++) {
          if (
            delayBeforeStart > (i - 1) * 1000 &&
            delayBeforeStart < i * 1000
          ) {
            if (i === 0) {
              this.countdown = "GO!";
              this.$store.state.isWatingInVersus = false;
              console.log("go!!!!!!! " + this.$store.state.isWatingInVersus);
              this.$emit("race-start");
            } else {
              this.$store.state.isWatingInVersus = true;
              this.countdown = i.toString();
            }
          }
        }
      }
    },
    nowMs() {
      const localNowMs = Date.now();
      return localNowMs - this.localTimeMsAhead;
    },
    // initTouchEvent() {
    //   var el = document.getElementById("boost");
    //   el.addEventListener("touchstart", this.useBoost, false);
    // },
    startChronoRefresh() {
      this.chronoTimer = setInterval(() => {
        if (this.startTimeMs === 0) {
          this.currentTime = 0;
        } else if (this.currentLap > 0 && this.laps.length < this.numLaps) {
          const currentTime = this.nowMs() - this.startTimeMs;
          if (currentTime > 0) {
            this.currentTime = currentTime;
          } else {
            this.currentTime = 0;
          }
          if (this.raceMode === "Versus") {
            const delayBeforeStart = -currentTime;
            this.displayCountdown(delayBeforeStart);
          }
        }
        //console.log(`currentLap ${this.currentLap} lap len ${this.laps.length} num laps ${this.numLaps}`);
      }, 87); // avoid using round number like 100 to give illusion of constant refresh
    },
  },
  computed: {
    ...mapState(["isFold3"]),
    bestLapStr: function () {
      return msToString(bestLap(this.laps));
    },
    currentTimeStr: function () {
      return msToString(this.currentTime);
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/components/GameUi.css";
</style>
