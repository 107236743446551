<template>
  <div class="boostContainer">
    <img
      id="boost"
      :width="size"  
      v-if="boostAvailable"
      src="@/assets/images/control/Boost-On.png"
    />
    <img
      v-else
      :width='size' 
      src="@/assets/images/control/Boost-Off.png"
    />
  </div>
</template>

<script>
import store from "@/store";
import {boostMutation} from "../../../services/api/mutations";
import Vue from "vue";

export default {
  name: "Boost",
  data() {
    return {
      boostAvailable: true,
      boostValue: 1.25,
      size:40,
    };
  },
  mounted() {
    this.initTouchEvent();
  },

  methods: {
    initTouchEvent() {
      var el = document.getElementById("boost");
      //el.addEventListener("touchstart", this.useBoost, false);
      el.addEventListener("touchstart", this.useBoost, false);
    },
    boostUp() {
      this.boostAvailable = true;
    },
    boostDeactivation() {
      store.commit("Control/setAccelerationModifier", 1);
    },
   async useBoost() {
      if (this.boostAvailable == true) { //resp
        await this.ActivateBoost(this.boostAvailable);
        // store.commit("Control/setAccelerationModifier", this.boostValue);
        // setTimeout(this.boostUp, 15000);
        // setTimeout(this.boostDeactivation, 3000);
        // this.boostAvailable = false;
        
      } else {
        console.log("boost not enabled");
      }
    },

      async ActivateBoost (boost){
        console.log("user activates boost " + boost);
        console.log("id Boost player " + this.$store.state.currentUserId);
        const resp = await Vue.$carsBackendConnector.client.mutate({
        mutation: boostMutation,
        variables: {
          boostActivation: {
            playerId: parseInt(this.$store.state.currentUserId),
            activation: boost,
            transactionId: null, // null 
          },
        },
      });
      console.log("Response_APOLLO", resp);

      if(resp.data.boostActivation && resp.data.boostActivation.activation === true){
        console.log(" resp = "+ resp);
        // store.commit("Control/setAccelerationModifier", this.boostValue);
        setTimeout(this.boostUp, resp.data.boostActivation.timeBeforeActivation); 
        setTimeout(this.boostDeactivation, resp.data.boostActivation.boostTime); 
        this.boostAvailable = false;
      }else{
        console.log(" Backend doesnt allowed the boost Activation",  resp);
      }
      return resp;
      },
  },
};
</script>
<style scoped>
@import "../../../assets/css/controls/action/Boost.css";
</style>
