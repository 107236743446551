<template>
    <div class="newSliderTurnContainer">
        <boost v-if="!isAcceleration"></boost>
    <div class="sliderTurnContainer">
      <input
            v-on:touchend="touchend"
            v-on:mouseup="touchend"
            v-on:mousedown="touchstart"
            v-on:touchstart="touchstart"
            type="range"
            :min="min"
            :max="max"
            class="sliderTurn"
            :value="value"
            v-on:input="updateValue($event.target.value)"
          />
        </div>
    </div>
    </template>
    <script>
    import boost from "../actions/boost.vue";
    export default {
        name: "newSliderTurn",
        data() {
        return {
          value: 0,
          max: 100,
          min:-100,
          mode: "end",
          resetTimout: null,
        };
      },
      props: {
           isAcceleration: Boolean,
      },
      components : { 
        boost, 
    },
      methods: {
        touchstart: function () {
          this.mode = "move";
          clearTimeout(this.resetTimout);
          this.updateValue(this.value);
        },
        touchend: function () {
          this.mode = "end";
          this.resetTimout = setTimeout(() => {
            this.updateValue(0);
          }, 0);
        },
        updateValue: function (value) {
          this.value = value;
          console.log(this.value);
          this.$emit("newSliderTurnInput", {
            mode: this.mode,
            value: value,
          });
        },
      },
    }
    </script>
    <style scoped>
    @import "../../../assets/css/controls/NewSliderTurn.css";
    </style>
    