import { createPlayer } from "../../services/api";
import { apolloClient } from "../../vue-apollo";

const state = {
  autStatus: false,
  user: {
    username: "",
    pseudo: "",
    email: "",
    lastName: "",
  },
  token: "" || null,
};
const getters = {
  user: (state) => state.user,
  isAuth: (state) => !!state.token,
  autStatus: (state) => state.autStatus,
};
const actions = {
  async registerUser(parent, userData) {
    console.log(userData);
    const resp = await apolloClient.mutate({
      mutation: createPlayer,
      variables: {
        registerUser: {
          username: userData.pseudo,
          lastName: userData.lastName,
          email: userData.email,
        },
      },
    });
    console.log("Response_APOLLO", resp);
    return resp;
    // commit('LOGIN_USER', {
    //   user: userData
    // })
    // commit('SET_TOKEN', 'test')
  },
  async loginUser({ commit }, data) {
    console.log(data);
    commit("LOGIN_USER", data.user);
    commit("SET_TOKEN", data.token);
  },
};
const mutations = {
  LOGIN_USER(state, payload) {
    state.user = payload;
    state.autStatus = true;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
